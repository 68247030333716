<template>
  <div class="vehicle card" :class="{ 'invalid-data': !isValid }">
    <router-link :to="vRoute" class="no-gutters row h-100">
      <div class="col-1 d-flex align-items-center justify-content-center">
        <VehicleIcon />
      </div>
      <div class="col-xs-8">
        <div class="card-body">
          <span class="card-title title-headline text-uppercase">
            {{ vehicle.displayValue }}
          </span>
          <br />
          <span class="card-subtitle">{{ vehicle.plateNumber }}</span>
          <div v-if="!isValid">
            <span class="badge badge-invalid-data">!</span>
            &nbsp;
            <span class="text-white">{{ validationLabel }}</span>
          </div>
          <div v-else class="text-evenir-content-light">
            {{ validationLabel }}
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import VehicleIcon from '@/components/svg/VehicleIcon.vue'
import { Routes } from '@/router.js'

export default {
  components: { VehicleIcon },
  props: {
    vehicle: { type: Object, required: true }
  },
  computed: {
    vRoute() {
      return {
        ...Routes.MY_VEHICLE,
        params: { id: this.vehicle.id }
      }
    },
    certificate() {
      return this.$store.getters.getVehicleRegistrationCertificate(
        this.vehicle.id
      )
    },
    validationStatus() {
      return this.certificate ? this.certificate.isValid : undefined
    },
    isValid() {
      return this.validationStatus === true
    },
    validationLabel() {
      return this.validationStatus === undefined
        ? 'En cours de validation'
        : this.validationStatus
        ? 'Validée'
        : 'Invalide'
    }
  }
}
</script>

<style></style>
