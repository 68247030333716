var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vehicle card", class: { "invalid-data": !_vm.isValid } },
    [
      _c(
        "router-link",
        { staticClass: "no-gutters row h-100", attrs: { to: _vm.vRoute } },
        [
          _c(
            "div",
            {
              staticClass:
                "col-1 d-flex align-items-center justify-content-center"
            },
            [_c("VehicleIcon")],
            1
          ),
          _c("div", { staticClass: "col-xs-8" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "span",
                { staticClass: "card-title title-headline text-uppercase" },
                [_vm._v(" " + _vm._s(_vm.vehicle.displayValue) + " ")]
              ),
              _c("br"),
              _c("span", { staticClass: "card-subtitle" }, [
                _vm._v(_vm._s(_vm.vehicle.plateNumber))
              ]),
              !_vm.isValid
                ? _c("div", [
                    _c("span", { staticClass: "badge badge-invalid-data" }, [
                      _vm._v("!")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-white" }, [
                      _vm._v(_vm._s(_vm.validationLabel))
                    ])
                  ])
                : _c("div", { staticClass: "text-evenir-content-light" }, [
                    _vm._v(" " + _vm._s(_vm.validationLabel) + " ")
                  ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }