var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "charging-point card" },
    [
      _c(
        "router-link",
        { staticClass: "no-gutters row h-100", attrs: { to: _vm.cpRoute } },
        [
          _c(
            "div",
            {
              staticClass:
                "col-1 d-flex align-items-center justify-content-center"
            },
            [_c("ChargingPointIcon")],
            1
          ),
          _c("div", { staticClass: "col-11 d-flex align-items-center" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "span",
                { staticClass: "card-title title-headline text-uppercase" },
                [_vm._v("Point de recharge #" + _vm._s(_vm.chargingPoint.id))]
              ),
              _c("br"),
              _c("span", { staticClass: "card-subtitle" }, [
                _vm._v(" " + _vm._s(_vm.chargingPoint.address) + " ")
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }