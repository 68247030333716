var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "33.54",
        height: "21.15",
        viewBox: "0 0 33.54 21.15"
      }
    },
    [
      _c("path", {
        staticClass: "a",
        attrs: {
          d:
            "M30.62,20.65H2.92A2.416,2.416,0,0,1,.5,18.23V2.92A2.416,2.416,0,0,1,2.92.5h27.7a2.416,2.416,0,0,1,2.42,2.42V18.23A2.416,2.416,0,0,1,30.62,20.65Z"
        }
      }),
      _c("line", {
        staticClass: "b",
        attrs: { x2: "5.69", transform: "translate(5.03 9.28)" }
      }),
      _c("line", {
        staticClass: "b",
        attrs: { x2: "9.73", transform: "translate(4.53 12.32)" }
      }),
      _c("line", {
        staticClass: "b",
        attrs: { x2: "4.87", transform: "translate(16.77 12.32)" }
      }),
      _c("line", {
        staticClass: "b",
        attrs: { x2: "24.48", transform: "translate(4.53 16.18)" }
      }),
      _c("path", {
        staticClass: "c",
        attrs: { d: "M10.72,6.32,7.9,4.35,5.03,6.32Z" }
      }),
      _c("line", {
        staticClass: "b",
        attrs: { y2: "2.52", transform: "translate(5.82 6.76)" }
      }),
      _c("line", {
        staticClass: "b",
        attrs: { y2: "2.52", transform: "translate(9.91 6.76)" }
      }),
      _c("line", {
        staticClass: "b",
        attrs: { y2: "2.52", transform: "translate(7.86 6.76)" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }