<template>
  <div id="account">
    <HeaderNavigation title="mon compte" />
    <div class="title-container-wrapper">
      <b-row class="main-container container align-items-center no-gutters">
        <b-col cols="8">
          <b-media>
            <UserPhoto :size="70" slot="aside" />
            <b-row class="title-headline align-items-center">
              <b-col>
                <span>{{ userInfo.fullName }}</span>
              </b-col>
            </b-row>
            <b-row class="title-headline-sub align-items-center">
              <b-col>
                <span class="username">{{ userInfo.username }}</span>
              </b-col>
            </b-row>
          </b-media>
        </b-col>
        <b-col cols="4" class="text-right">
          <b-button
            variant="dark"
            class="btn-modify"
            :to="routes.ACCOUNT_EDIT"
            router-tag="button"
            >MODIFIER</b-button
          >
        </b-col>
      </b-row>
    </div>
    <b-container id="account-container" class="main-container">
      <b-row>
        <b-col>
          <h3 class="text-evenir-title">MES INFORMATIONS</h3>
          <b-row class="content-row">
            <b-col cols="5" class="text-evenir-title">E-MAIL</b-col>
            <b-col cols="7" class="text-evenir-content">{{
              this.userInfo.email
            }}</b-col>
          </b-row>
          <b-row class="content-row">
            <b-col cols="5" class="text-evenir-title">Pièce d'identité</b-col>
            <b-col
              cols="7"
              class="text-evenir-content"
              :class="{ 'invalid-data': !isIdCardValid }"
            >
              <span v-if="!isIdCardValid" class="badge badge-invalid-data"
                >!</span
              >
              {{ this.idCardValidity }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 class="text-evenir-title">Mon Véhicule</h3>
          <b-row v-if="hasVehicles">
            <b-col>
              <Vehicle
                v-for="vehicle in vehicles"
                :key="vehicle.id"
                :vehicle="vehicle"
              />
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col class="text-center">
              <img
                src="@/assets/svg/small-vehicle.svg"
                width="35"
                class="text-evenir-content-decorator"
              />
              <p class="text-evenir-content-p">
                Vous n’avez aucun véhicule enregistré dans l’application.
                <br />Devenez-vous aussi un utilisateur du réseau de la
                communauté :
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <b-button
                v-if="vehicles.length == 0"
                variant="light"
                :to="routes.REGISTER_DRIVER"
                router-tag="button"
                class="evenir-squared-button"
                >+</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 class="text-evenir-title text-uppercase">
            Mes points de recharge
          </h3>
          <b-row v-if="hasChargingPoints">
            <b-col>
              <ChargingPointCard
                v-for="(chargingPoint, idx) in chargingPoints"
                :key="idx"
                :chargingPoint="chargingPoint"
              />
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col class="text-center">
              <img
                src="@/assets/svg/small-charging-point.svg"
                width="11"
                class="text-evenir-content-decorator"
              />
              <p class="text-evenir-content-p">
                Vous n’avez aucun point de recharge enregistré dans
                l’application.
                <br />Devenez-vous aussi un hôte du réseau de la communauté :
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <b-button
                variant="light"
                :to="routes.REGISTER_PROVIDER"
                router-tag="button"
                class="evenir-squared-button"
                >+</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 class="text-evenir-title">Mes coordonnées bancaires</h3>
          <b-card
            v-for="pm in creditCards"
            :key="pm.id"
            class="credit-card"
            :class="{ 'invalid-data': !isCreditCardValid }"
            no-body
          >
            <router-link class="no-gutters row h-100" :to="pmRoute(pm.id)">
              <div
                class="col-1 d-flex align-items-center justify-content-center"
              >
                <IdCardIcon />
              </div>
              <b-card-body>
                <b-card-title
                  title-tag="span"
                  class="title-headline text-uppercase"
                  >CARTE BANCAIRE</b-card-title
                >
                <br />
                <b-card-sub-title sub-title-tag="span" sub-title-text-variant=""
                  >XXXX XXXX XXXX {{ pm.card.last4 }}</b-card-sub-title
                >
                <div v-if="!isCreditCardValid">
                  <span class="badge badge-invalid-data">!</span>
                  &nbsp;
                  <span class="text-white">non valide</span>
                </div>
                <div v-else class="text-evenir-content-light">
                  Validée
                </div>
              </b-card-body>
            </router-link>
          </b-card>
          <b-card
            v-if="hasIban"
            class="rib"
            :class="{ 'invalid-data': !isIbanValid }"
            no-body
          >
            <a class="no-gutters row h-100" href="#">
              <div
                class="col-1 d-flex align-items-center justify-content-center"
              >
                <RibIcon />
              </div>
              <b-card-body>
                <b-card-title
                  title-tag="span"
                  class="title-headline text-uppercase"
                  >RIB</b-card-title
                >
                <br />
                <b-card-sub-title
                  sub-title-tag="span"
                  sub-title-text-variant=""
                  >{{ iban.number }}</b-card-sub-title
                >
              </b-card-body>
            </a>
          </b-card>
          <b-row v-if="$store.state.creditCards.length == 0">
            <b-col class="text-right">
              <b-button
                variant="light"
                class="evenir-squared-button"
                router-tag="button"
                :to="routes.ADD_PAYMENT_METHOD"
                >+</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import UserPhoto from '@/components/Account/UserPhoto.vue'
import Vehicle from '@/components/Account/Vehicle.vue'
import ChargingPointCard from '@/components/Account/ChargingPointCard.vue'
import IdCardIcon from '@/components/svg/IdCardIcon.vue'
import RibIcon from '@/components/svg/RibIcon.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { Routes } from '@/router.js'

export default {
  name: 'account',
  components: {
    HeaderNavigation,
    UserPhoto,
    Vehicle,
    ChargingPointCard,
    IdCardIcon,
    RibIcon
  },
  data() {
    return {
      routes: Routes
    }
  },
  computed: {
    ...mapState(['userInfo', 'creditCards', 'iban', 'documents']),
    ...mapGetters([
      'vehicles',
      'chargingPoints',
      'hasVehicles',
      'hasChargingPoints'
    ]),
    isIdCardValid() {
      return !!this.documents.idCard.isValid
    },
    idCardValidity() {
      return this.documents.idCard.isValid == undefined
        ? 'EN COURS DE VALIDATION'
        : this.isIdCardValid
        ? 'VALIDE'
        : 'INVALIDE'
    },
    isCreditCardValid() {
      return true
    },
    hasIban() {
      return !!this.iban.number
    },
    isIbanValid() {
      return true
    }
  },
  created() {
    // Update data
    this.loadUserInfo({ api: this.$apiService, refresh: true }).catch(err =>
      console.error('[App] Uncaught error while loading user info: ', err)
    )

    this.loadUserPhoto({ api: this.$apiService, refresh: true }).catch(err =>
      console.error('[App] Uncaught error while loading user photo: ', err)
    )

    this.loadIdCard({ api: this.$apiService, refresh: true }).catch(err =>
      console.error(
        "[App] Uncaught error while updating store's user ID card: ",
        err
      )
    )

    this.loadVehicles({ api: this.$apiService, refresh: true }).catch(err =>
      console.error('[App] Uncaught error while loading vehicles: ', err)
    )

    this.loadChargingPoints({ api: this.$apiService, refresh: true }).catch(
      err =>
        console.error(
          '[App] Uncaught error while loading charging points: ',
          err
        )
    )

    this.loadCreditCards({ api: this.$apiService, refresh: true }).catch(err =>
      console.error('[App] Uncaught error while loading credit cards: ', err)
    )

    this.loadIban({ api: this.$apiService, refresh: true }).catch(err =>
      console.error('[App] Uncaught error while loading IBAN: ', err)
    )
  },
  methods: {
    ...mapActions([
      'loadUserInfo',
      'loadUserPhoto',
      'loadIdCard',
      'loadVehicles',
      'loadChargingPoints',
      'loadCreditCards',
      'loadIban'
    ]),
    pmRoute(pmId) {
      return { ...Routes.EDIT_PAYMENT_METHOD, params: { id: pmId } }
    }
  }
}
</script>
<style lang="scss">
@import '@/styles.scss';

#account {
  .title-headline-sub {
    font-weight: $font-weight-light;
  }

  .btn-modify {
    width: 98px;
  }
}
</style>
<style lang="scss">
@import '@/styles.scss';

#account {
  .evenir-squared-button {
    margin-bottom: 15px;
  }

  .vehicle,
  .charging-point,
  .credit-card,
  .rib {
    background-color: $base-text-back-color;
    border-radius: 10px;
    border: none;
    height: 100px;
    margin-bottom: 10px;
    a {
      padding-left: 30px;
      .card-body {
        text-decoration: none;
        .card-subtitle {
          color: #cecece;
          font-size: 14px;
          opacity: 1;
          letter-spacing: 1px;
        }
      }
      &:hover {
        text-decoration: none;
      }
    }
    svg {
      fill: $dark;
      stroke: $dark;
    }
    &.invalid-data {
      background-color: $invalid-data-color;
      a {
        .card-body {
          .card-subtitle {
            color: #fff;
            opacity: 0.5;
          }
        }
      }
      &:hover {
        svg {
          fill: #fff;
          stroke: #fff;
        }
        .title-headline {
          color: #fff;
        }
      }
    }
    &:active {
      background-color: #ff995a;
    }
  }
}
</style>
