<template>
  <div class="charging-point card">
    <router-link :to="cpRoute" class="no-gutters row h-100">
      <div class="col-1 d-flex align-items-center justify-content-center">
        <ChargingPointIcon />
      </div>
      <div class="col-11 d-flex align-items-center">
        <div class="card-body">
          <span class="card-title title-headline text-uppercase"
            >Point de recharge #{{ chargingPoint.id }}</span
          >
          <br />
          <span class="card-subtitle">
            {{ chargingPoint.address }}
          </span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import ChargingPointIcon from '@/components/svg/ChargingPointIcon.vue'
import { Routes } from '@/router.js'

export default {
  name: 'charging-point-card',
  components: { ChargingPointIcon },
  props: {
    chargingPoint: { type: Object, required: true }
  },
  computed: {
    cpRoute() {
      return {
        ...Routes.MY_CHARGING_POINT,
        params: { id: this.chargingPoint.id }
      }
    }
  }
}
</script>
